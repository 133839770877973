const ConstApiUrls = ({

    // AUTH_SERVICE_URL: 'http://localhost:8686/api/auth/',
    // TEST_SERVICE_URL: 'http://localhost:8686/api/test/',
    // API_SERVICE_URL: 'http://localhost:8686/api/pirjatim/'


    // AUTH_SERVICE_URL: 'https://des-green.com:8686/api/auth/',
    // TEST_SERVICE_URL: 'https://des-green.com:8686/api/test/',
    // API_SERVICE_URL: 'https://des-green.com:8686/api/pirjatim/'

    AUTH_SERVICE_URL: 'https://point.jatimprov.go.id:8686/api/auth/',
    TEST_SERVICE_URL: 'https://point.jatimprov.go.id:8686/api/test/',
    API_SERVICE_URL: 'https://point.jatimprov.go.id:8686/api/pirjatim/'

})
export default ConstApiUrls
