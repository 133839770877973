<template>
  <nav>
    <v-toolbar :flat="currentUser===null" elevation="0" color="#F5F5FF5F">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text" v-if="currentUser"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">PIR</span>
        <span>JATIM</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down row--dense" >
        <v-menu offset-y v-if="currentUser">
          <template v-slot:activator="{ on, attrs }" >
            <v-btn
                plain
                v-bind="attrs"
                v-on="on"
            >
              <span small>{{ currentUser.username }} </span>
              <v-avatar color="grey lighten-1" size="36">
                <v-img
                    :lazy-src="lookupImageUrlLazy(currentUser.avatarImage)"
                    :src="lookupImageUrl(currentUser)"
                ></v-img>
              </v-avatar>
            </v-btn>

          </template>
          <v-list color="grey lighten-4" >
            <v-list-item
                v-for="link in top_menus" :key="link.title" route :to="link.route"
            >

              <span class="align-center align-content-center">{{link.title}}</span>
            </v-list-item>
            <v-list-item>
              <v-btn plain elevation="0" color="gray darken-1" @click="logOut" small>
                Keluar
                <v-icon color="red">mdi-power</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>

        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class=" mx-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                rounded
                icon
                class="px-5"
                x-small
            >
              <v-icon color="black">mdi-translate</v-icon>
              <v-avatar size="18" class="elevation-1" >
                <v-img :src="getFlagImage(lang)" ></v-img>
              </v-avatar>
            </v-btn>

          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in menu_language"
                :key="index"
                dense
            >
              <v-list-item-title >
                <v-avatar size="22" class="elevation-1">
                  <v-img :src='getFlagImage(item.id)' ></v-img>
                </v-avatar>
                <v-btn small plain elevation="0" @click="languageChange(item.id)">
                  {{ item.description }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>
      </v-toolbar-items>

    </v-toolbar>


     <v-navigation-drawer app class="bottom-gradient" v-model="drawer"  v-if="showNavbar">

       <v-layout column align-center>
        <v-flex class="mt-5">
            <v-img
              lazy-src="../assets/images/logo_sidebar_small.png"
              max-height="200"
              max-width="200"
              src="../assets/images/logo_sidebar_small.png"
            ></v-img>

        </v-flex>
        <v-flex class="mt-4 mb-3">
        </v-flex>
      </v-layout>

       <v-divider></v-divider>

       <v-list>

        <v-list-item route to='/dashboard-utama'>
          <v-list-item-icon>
            <v-icon>mdi-rocket</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle>Dashboard</v-list-item-subtitle>
        </v-list-item>

        <v-list-group
          v-for="nav_drawer in nav_drawers.filter(x=>x.visible)"
          :key="nav_drawer.title"
          :prepend-icon="nav_drawer.icon"
          no-action
          small
          v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title"> </v-list-item-title>
            </v-list-item-content>
          </template>

              <v-list-item
                v-for="submenu in nav_drawer.items.filter(x => x.visible)"
                :key="submenu.title"
                link
                router :to="submenu.route"  class="navbar_submenu_color" >

                <template>
                  <v-list-item-content>
                    <v-list-item-title class="body-2" v-text="submenu.title" > </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small >{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>

        </v-list-group>

    </v-list>

    </v-navigation-drawer>

 
  </nav>
</template>

<script>
import ERole from "../models/e-role";
import FileService from "../services/apiservices/file-service"
import TestService from "../services/test-service";

export default {
  components: {  },
  data() {
    return {
      menu_language:[
        {id: 'en', description: 'English', icon: 'flag_en.jpg'}, {id: 'id', description: 'Indonesia', icon: 'flag_id.jpg'}
      ],

      top_menus: [
        { icon: 'mdi-help-box', title: 'Ubah Profile', route: '/change-profile', visible: true  },
      ],

      drawer: true,

    }
  },
  watch:{
  },
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },
    showNavbar() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles;
      }
      return false;
    },

    nav_drawers() {
      return [
        {
          title: 'Potensi-Kawasan',
          icon: 'mdi-star',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN,  ERole.ROLE_USER, ERole.ROLE_USER_1,  

               ].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Potensi-Ipro', route: '/admin-potensi', icon: 'mdi-star', visible:  [ERole.ROLE_ADMIN,  ERole.ROLE_USER, ERole.ROLE_USER_1,
              ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Kawasan', route: '/admin-kawasan', icon: 'mdi-puzzle', visible:  [ERole.ROLE_ADMIN,  ERole.ROLE_USER, ERole.ROLE_USER_1,
              ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Sector Type', route: '/admin-sector-type', icon: '', visible:  [ERole.ROLE_ADMIN ].some(x =>this.currentUser.roles.includes(x))  },
            {title: 'Komoditi Type', route: '/admin-komoditi-type', icon: '', visible:  [ERole.ROLE_ADMIN ].some(x =>this.currentUser.roles.includes(x))  },
            {title: 'Komoditi', route: '/admin-komoditi', icon: '', visible:  [ERole.ROLE_ADMIN ].some(x =>this.currentUser.roles.includes(x))  },

          ],
        },
        {
          title: 'Infrastruktur',
          icon: 'mdi-hospital-building',
          active: false,
          visible: [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ERole.ROLE_USER_1,  
                 ].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Infrastruktur', route: '/admin-infrastruktur', icon: 'mdi-hospital-building', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Jenis', route: '/admin-jenis-infrastruktur', icon: '', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        {
          title: 'Daya Dukung',
          icon: 'mdi-power-plug',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
              ].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Daya Dukung', route: '/admin-dayadukung', icon: 'mdi-power-plug', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Jenis', route: '/admin-jenis-dayadukung', icon: '', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        {
          title: 'Insentif',
          icon: 'mdi-cash-100',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
          ].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Insentif', route: '/insentif', icon: 'mdi-cash-100', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Jenis', route: '/jenis-insentif', icon: '', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        {
          title: 'Wilayah',
          icon: 'mdi-map',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER, ERole.ROLE_USER_1,
          ].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Wilayah Adm', route: '/admin-wilayah', icon: 'mdi-map', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER, ].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },

        {
          title: 'Laporan',
          icon: 'mdi-chart-pie',
          active: false,
          visible: true,
          items: [
            {title: 'Laporan', route: '/reports', icon: '', visible:  true},
            {title: 'Komisi', route: '/report-komisi', icon: '', visible:  true},
          ],
        },
        {
          title: 'Setup',
          icon: 'mdi-cog',
          active: false,
          visible:
              [ERole.ROLE_ADMIN].some(x => this.currentUser.roles.includes(x)
              ),
          items: [
            {title: 'Satuan', route: '/setup-satuan', icon: 'mdi-cogs', visible:
                  [ERole.ROLE_ADMIN, ERole.ROLE_USER].some(x => this.currentUser.roles.includes(x))
            },
          ],
        },

        {
          title: 'Admin',
          icon: 'mdi-cog-outline',
          active: false,
          visible:  [ERole.ROLE_ADMIN,    ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Pengguna', route: '/admin-sistem', icon: '', visible:  [ERole.ROLE_ADMIN ].some(x =>this.currentUser.roles.includes(x))  },
            {title: 'Struktur Organisasi', route: '/admin-struktur-organisasi', icon: 'mdi-file-tree', visible:  [ERole.ROLE_ADMIN,     ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },

      ]
    },

    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    }
  },

  methods: {
    getFlagImage(fileName){
      const lang = fileName
      if (lang ===undefined){
        return require('@/assets/i18n/flag_id.jpg')
      }else {
        const langJson =  this.menu_language.filter( item => item.id === `${lang}`)
        return require('@/assets/i18n/' + langJson[0].icon)
      }
    },

    languageChange(value){
			this.lang = value
      this.$i18n.locale = this.lang
    },

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    // signOut() {
    //     localStorage.setItem("authenticated", false)
    //     this.$store.commit("setAuthenticatedFalse")
    //     this.$router.push( { name: "Login"})
    // },

    lookupImageUrl(item){

      // console.log(JSON.stringify(item))
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }

    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
  },
  mounted() {
    TestService.getAdminBoard().then(
        ()=>{
        },
        error=>{
          console.log(`${error.response.status}`)
          if (error.response.status===401){
            if (this.currentUser !== undefined || this.currentUser !== ''){
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }

          }
        }
    )

  }

}
</script>


<style scoped>
</style>