
export const helperModule = {
  namespaced: true,
  state: {
    sumOfItem: 9999999,

  },
  actions: {
    setSumOfItem({commit}, payload){
      commit('mutateSumOfItem', payload)
    },
  },
  mutations: {
    mutateSumOfItem(state, payLoad){
      state.sumOfItem = payLoad
    },

  },


};
