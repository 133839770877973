const ERole = Object.freeze({
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_USER: "ROLE_USER",
    ROLE_USER_1: "ROLE_USER_1",

    ROLE_MANAGER: "ROLE_MANAGER",
    ROLE_MANAGER_1: "ROLE_MANAGER_1",
    ROLE_MANAGER_2: "ROLE_MANAGER_2",

    ROLE_PENGAWASAN: "ROLE_PENGAWASAN",
    ROLE_PHO_FHO_1: "ROLE_PHO_FHO_1",
    ROLE_FISIK_1: "ROLE_FISIK_1",
    ROLE_PENYERAHAN_1: "ROLE_PENYERAHAN_1",

    ROLE_KEUANGAN: "ROLE_KEUANGAN",
    ROLE_KEUANGAN_1: "ROLE_KEUANGAN_1",

    ROLE_PUBLIC: "ROLE_ROLE_PUBLIC_1",
    ROLE_PUBLIC_1: "ROLE_ROLE_PUBLIC_1",

    ROLE_REKANAN_1: "ROLE_REKANAN_1"
})
export default ERole