export default class FPotensi {
  constructor(
    id,
    kode1="",
    kode2 ="",
    description = "",
    fdivisionBean = 0,
    avatarImage = "",

    statusActive =true,
    baseId,
    lang,


    fsectorTypeBean =0,
    fkomoditiTypeBean =0,
    fsubAreaBean =0,

    address ="",
    district="",
    city="",
    state="",

    /**
     * POTENSI
     * PELUANG
     * IPRO
     *
     * ---masuk ke tabel potensi---
     * KI
     * KIH
     * KPI
     * KEK
     */
    jenis ="POTENSI",


    lat  =0.0,
    lon  =0.0,

    tahun  =2021,

    nilaiInvestasi  =0.0,
    payBackPeriode  =0.0,
    npv  =0.0,
    irr  =0.0,

    namaKontak1  ="",
    alamatKontak1  ="",
    kotaKontak1  ="",
    emailKontak1  ="",
    phoneKontak1  ="",

    namaKontak2  ="",
    alamatKontak2  ="",
    kotaKontak2  ="",
    emailKontak2  ="",
    phoneKontak2  ="",

    /**
     *  KAWASAN >> INFORMASI UMUM
     */
    areaTotal  =0.0, //Satuan Ha
    areaPengembangan  =0.0, //Satuan Ha
    areaKosong  =0.0, //Satuan Ha
    skemaPembayaran  ="",


    /**
     *  KAWASAN >> KEPEMILIKAN LAHAN
     */
    sewa  =0.0,
    biayaSewa  =0.0,
    akuisisiPembelian  =0.0,
    hargaPembelian  =0.0,

    /**
     *  KAWASAN >> BANGUNANA SIAP PAKAI
     */
    bangunanKantor  =0,
    bangunanKomersial  =0,
    bangunanPabrik  =0,

    /**
     *  KAWASAN >> Infrastruktur
     */
    jalanUtama  =0.0, //lebar dalam Meter
    jalanSekunder  =0.0, //leber dalam Meter
    jalanTersier =0.0,

    /**
     *  KAWASAN >> Utilitas
     */
    listrik  =0.0, //dalam Kva
    gasAlam  =0.0, //leber dalam Meter
    airDomestik  ="",
    kapasitas  =0.0, //dalam m3/hari
    pengolahanAirLimbah  =0.0, //dalam m3/hari


    /**
     *  KAWASAN >> Telekomunikasi
     */
    jumlahNomorTelp  =0, //satuan jumlah saluran kabel/nomor
    jenisInternet  ="",
    providerInternet  ="",

    /**
     *  KAWASAN >> Jarak Terdekat Terhadap
     */
    jarakKotaBesar  ="",
    jarakBandara  ="",
    jarakPelabuhan  ="",
    jarakAdminPerkantoran  ="",
    jarakTerminal  ="",
    jarakHotel  ="",
    jarakPemukiman  ="",

    /**
     *  KAWASAN >> Informasi Tenan
     */
    jumlahTenan  =0,
    blockTenanAvailable  =0,
    blockTenanReady  =0,

    /**
     *  KAWASAN >> Kreteria KIH
     */
    halalJenisPembatasZona  ="",
    halalLogistik  ="",
    halalLabDanLph  ="",

    bidangUsaha = "",
    remarkBool1 = false,
    remarkBool2 = false,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage =avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.baseId = baseId;
    this.lang = lang;


    this.fsectorTypeBean = fsectorTypeBean;
    this.fkomoditiTypeBean = fkomoditiTypeBean;
    this.fsubAreaBean = fsubAreaBean;
    this.jenis = jenis;

    this.address = address;
    this.district = district;
    this.city = city;
    this.state = state;

    this.lat = lat;
    this.lon = lon;
    this.tahun = tahun;

    this.nilaiInvestasi = nilaiInvestasi;
    this.payBackPeriode = payBackPeriode;
    this.npv = npv;
    this.irr = irr;

    this.namaKontak1 = namaKontak1;
    this.alamatKontak1 = alamatKontak1;
    this.kotaKontak1 = kotaKontak1;
    this.emailKontak1 = emailKontak1;
    this.phoneKontak1 = phoneKontak1;

    this.namaKontak2 = namaKontak2;
    this.alamatKontak2 = alamatKontak2;
    this.kotaKontak2 = kotaKontak2;
    this.emailKontak2 = emailKontak2;
    this.phoneKontak2 = phoneKontak2;



    this.areaTotal = areaTotal;
    this.areaPengembangan = areaPengembangan;
    this.areaKosong = areaKosong;
    this.skemaPembayaran = skemaPembayaran;

    this.sewa = sewa;
    this.biayaSewa = biayaSewa;
    this.akuisisiPembelian = akuisisiPembelian;
    this.hargaPembelian = hargaPembelian;

    this.bangunanKantor = bangunanKantor;
    this.bangunanKomersial = bangunanKomersial;
    this.bangunanPabrik = bangunanPabrik;

    this.jalanUtama = jalanUtama;
    this.jalanSekunder = jalanSekunder;
    this.jalanTersier = jalanTersier;

    this.listrik = listrik;
    this.gasAlam = gasAlam;
    this.airDomestik = airDomestik;
    this.kapasitas = kapasitas;
    this.pengolahanAirLimbah = pengolahanAirLimbah;

    this.jumlahNomorTelp = jumlahNomorTelp;
    this.jenisInternet = jenisInternet;
    this.providerInternet = providerInternet;

    this.jarakKotaBesar = jarakKotaBesar;
    this.jarakBandara = jarakBandara;
    this.jarakPelabuhan = jarakPelabuhan;
    this.jarakAdminPerkantoran = jarakAdminPerkantoran;
    this.jarakTerminal = jarakTerminal;
    this.jarakHotel = jarakHotel;
    this.jarakPemukiman = jarakPemukiman;

    this.jumlahTenan = jumlahTenan;
    this.blockTenanAvailable = blockTenanAvailable;
    this.blockTenanReady = blockTenanReady;

    this.halalJenisPembatasZona = halalJenisPembatasZona;
    this.halalLogistik = halalLogistik;
    this.halalLabDanLph = halalLabDanLph;

    this.bidangUsaha = bidangUsaha;
    this.remarkBool1 = remarkBool1;
    this.remarkBool2 = remarkBool2;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
