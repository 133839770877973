import axios from '../plugins/MyAxios';

export default {
    data() {
        return {
            country: '',
        };
    },
    mounted() {
        this.getCountry();
    },
    methods: {
        getCountry() {
            axios.get('')
                .then(response => {
                    if (response.data.status === 'success') {
                        this.country = response.data.country;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.country = 'Tidak dapat mengambil data negara.';
                });
        },
    },
};
