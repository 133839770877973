<template>
  <v-app>
    <NavDrawer v-if="currentUser !== null" />
    <MenuBar v-if="currentUser === null && showMenuBar != false" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
import MenuBar from "./components/MenuBar";
import FVisitorService from "@/services/apiservices/f-visitor-service";

export default {
  components: { MenuBar: MenuBar, NavDrawer },
  name: "App",
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },

    showMenuBar() {
      return this.$store.state.potensi.showMenuBar;
    },
  },
  methods: {
    initIcon() {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      // link.href = this.icon;
      link.href = "/assets/logo.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    isSessionActive() {
      const sessionExpiresAt = localStorage.getItem("sessionExpiresAt");
      if (!sessionExpiresAt) {
        return false; // Sesi tidak ada
      }

      // Periksa apakah waktu kedaluwarsa sesi telah tercapai
      return Date.now() < sessionExpiresAt;
    },

    addVisitor() {
      if (this.isSessionActive()) {
        console.log("Masih aktif");
      } else {
        localStorage.removeItem("sessionExpiresAt");

        navigator.geolocation.getCurrentPosition(function (position) {
          fetch(
            "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=" +
              position.coords.latitude +
              "&longitude=" +
              position.coords.longitude +
              "&localityLanguage=id"
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data.city);
              // console.log(data.countryCode);
              // console.log(data.principalSubdivision);
              // console.log(data.principalSubdivisionCode);
              // console.log(data.countryCode);
              // console.log(data.countryName);

              let fVisitor = {
                description: data.city,
                province: data.principalSubdivision,
                country: data.countryName,
              };
              FVisitorService.addFVisitorCount(fVisitor).then(() => {
                console.log("Visitor added");
                console.log(fVisitor);

                const sessionDuration = 4 * 60 * 60 * 1000; // Durasi sesi dalam milidetik (contoh: 4 x 60  menit)
                const sessionExpiresAt = Date.now() + sessionDuration;
                localStorage.setItem(
                  "sessionExpiresAt",
                  sessionExpiresAt.toString()
                );
              });
            })
            .catch((err) => {
              let fVisitor = {
                description: "Unknown Location",
                province: "Unknown Location",
                country: "Unknown Location",
              };
              FVisitorService.addFVisitorCount(fVisitor).then(() => {
                console.log("Visitor added");

                const sessionDuration = 4 * 60 * 60 * 1000; // Durasi sesi dalam milidetik (contoh: 4 x 60  menit)
                const sessionExpiresAt = Date.now() + sessionDuration;
                localStorage.setItem(
                  "sessionExpiresAt",
                  sessionExpiresAt.toString()
                );
              });

              console.warn(err.message);
            });
        });
      }
    },
  },
  created() {
    this.initIcon();
    this.addVisitor();
  },
  mounted() {
    this.initIcon();

    if (!this.currentUser) {
      //Kita tidak akan pernah push apapun jika belum login
      // this.$router.push('/login');
    }

    /**
     * AKAN DI BERIKAN VALIDATE EXPIRED TOKEN
     */
    // console.log(`Current user is ${JSON.stringify(this.currentUser)}`)
    console.log("###### MOUNTED App.Vue PERTAMA KALI ####");
  },
};
</script>
<style>
.color-gradient-1 {
  background-image: linear-gradient(to top left, #4777de 10%, transparent 90%);
}
.bottom-gradient {
  background-image: linear-gradient(to top left, #2362a9 10%, transparent 90%);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(134, 134, 234, 0.25) 10px
  );
}
.navbar_submenu_color {
  background-color: #81acdc;
}
</style>