import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n';
import id from './assets/i18n/id'
import en from './assets/i18n/en'


Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'id', // Lokal default
  messages: {
    en, id
    // id: {
    //   kantorPusat: 'Kantor Pusat',
    //   goodbye: 'Sampai jumpa!',
    // },
    // en: {
    //   kantorPusat: 'Home Office',
    //   goodbye: 'Goodbye!',
    // },

  }
});


import VeeValidate  from 'vee-validate'
Vue.use(VeeValidate);


import VNumeric from 'vuetify-numeric/vuetify-numeric.umd'
Vue.use(VNumeric)


Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
