import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth-module'
import { potensi } from './potensi-module'
import {langModule} from "@/store/lang-module";
import {helperModule} from "@/store/helper-module";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    potensi,
    langModule,
    helperModule

  }
})
